<template>
	<div class="o-wrap">
		<el-page-header @back="$router.go(-1)" content="招聘信息列表" />
		<el-button class="add-btn" @click="handleAdd">发布</el-button>
		<el-table :data="tableData" border>
			<el-table-column fixed prop="title" label="标题" />
			<el-table-column prop="view_num" label="阅读量">
			</el-table-column>
			<el-table-column label="状态">
				<template slot-scope="scope">
					<el-switch v-model="scope.row.state" active-color="#13ce66" :active-value="1" :inactive-value="0"
						@change="statusChange(scope.row)" />
				</template>
			</el-table-column>
			<el-table-column label="日期" prop="time" />
			<el-table-column label="操作">
				<template slot-scope="scope">
					<el-button type="text" size="small" @click="handleJump(scope.row)">查看</el-button>
					<el-button @click="handleEdit(scope.row)" type="text" size="small">编辑</el-button>
					<el-popconfirm title="确认删除吗？" @confirm="handleDel(scope.row)">
						<el-button type="text" size="small" slot="reference">删除</el-button>
					</el-popconfirm>
				</template>
			</el-table-column>
		</el-table>
		<el-pagination background layout="prev, pager, next" :total="total" :page-size="15" hide-on-single-page
			@current-change="currentChange" />
	</div>
</template>

<script>
	export default {
		data() {
			return {
				tableData: [],
				p: 1,
				total: 0
			}
		},
		beforeCreate(){
			this.$axios.post('/getsellerinfo').then(res => {
				if(res.level  == 3  || res.level == 5){
					this.$router.push('/recruitment')
				}else{
					this.$router.push('/miss/sx')
				}
			})
		},
		created() {
			this.getData()
		},
		methods: {
			getData() {
				this.$axios.post('/getnewslist', {
					typeid:15,
					p: this.p
				}).then(data => {
					this.total = Number(data.count)
					this.tableData = data.list
					document.body.scrollTop = document.documentElement.scrollTop = 0
				})
			},
			handleEdit(row) {
				this.$router.push(`/recruitment/revise?id=${row.id}`)
				
			},
			currentChange(page) {
				this.p = page
				this.getData()
			},
			statusChange(row) {
				this.$axios.post('/newstatus', {
					name: 'state',
					id: row.id,
					value: row.state
				}).then(data => {
					this.$message({
						type: 'success',
						message: '修改成功'
					})
				})
			},
			handleJump(row) {
				if(row.state == 0){
					this.$message({
						type: 'error',
						message: '没有打开状态~'
					})
				}else{
					if (row.category == '技术文库') {
						open(`${this.$store.state.ROOT_URL}/details/${row.id}.html`)
					} else if (row.category == '行业展会' || row.category == '产业链展会' || row.category == '产业链商城') {
						open(`${this.$store.state.ROOT_URL}/zszh_info/${row.id}.html`)
					} else {
						open(`${this.$store.state.ROOT_URL}/article/${row.id}.html`)
					}
				}
			},
			handleDel(row) {
				this.$axios.post('/newstatus', {
					name: 'userdel',
					id: row.id
				}).then(data => {
					this.$message({
						type: 'success',
						message: '删除成功'
					})
					this.getData();
				})
			},
			handleAdd() {
				this.$router.push('/recruitment/release')
			}
		}
	}
</script>

<style scoped>
	.o-wrap{
		width: 1600px;
		background-color: #fff;
	}	
	.el-button+span {
		margin-left: 10px;
	}

	.el-pagination {
		text-align: center;
		margin-top: 20px;
	}
	.add-btn{
		margin:10px 0 20px 20px ;
	}
</style>
